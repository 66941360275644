import { useEffect, useState } from 'react';

export interface Review {
  name: string;
  comment: string;
  rating: number;
  imgAvatar: string;
  time: string;
}

const useGoogleReviewData = (url?: string) => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [reviewCount, setReviewCount] = useState<number>(0);
  const [rating, setRating] = useState<number>(0);

  useEffect(() => {
    setLoading(true);
    if (!url) return;
    const uri = new URL(`${process.env.REACT_APP_API}/reviews`);
    uri.searchParams.append('url', url);
    const options = {
      method: 'GET',
      headers: {
        authority: 'www.google.com',
        accept: '*/*',
        'accept-language': 'en-US,en;q=0.7',
        referer: 'https://www.google.com/',
        'sec-fetch-dest': 'empty',
        'sec-fetch-mode': 'cors',
        'sec-fetch-site': 'same-origin',
        'sec-gpc': '1',
        'user-agent':
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/110.0.0.0 Safari/537.36',
        'x-goog-ext-353267353-bin': 'IM+ACQ==',
        'x-maps-diversion-context-bin': 'CAE=',
      },
    };
    fetch(uri, options)
      .then((res) => res.text())
      .then((text) => {
        const revJson = JSON.parse(text.substring(4));
        const reviewArr: Review[] = [];
        setReviewCount(revJson[5][4]);
        setRating(revJson.at(-1)[0].at(-1));
        for (const review of revJson[2]) {
          // console.log(revJson);
          const name = review[0][1];
          const imgAvatar = review[0][2];
          const comment = review[3];
          const rating = review[4];
          const date = new Date(review[27]);

          const time = new Intl.DateTimeFormat('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          }).format(date);

          reviewArr.push({ name, comment, rating, imgAvatar, time });
        }
        setReviews([...reviews, ...reviewArr]);
      })
      .finally(() => setLoading(false));
  }, []);

  return { reviews, isLoading, reviewCount, rating };
};

export default useGoogleReviewData;

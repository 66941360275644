import './App.css';
import { GoogleReviews } from './components/GoogleReview';

export default function App() {
  const url = process.env.REACT_APP_REVIEW_URL;
  return url ? (
    <div>
      <GoogleReviews reviewSite={url} />
    </div>
  ) : null;
}

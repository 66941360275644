import { JsxElement } from 'typescript';
import useGoogleReviewData, { Review } from '../hooks/useGoogleReviewData';
import '../App.css';
import { ReactComponent as GoogleIcon } from '../assets/google-logo.svg';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { CardSlider, MAX_CARDS } from './cardSlider';
import { ReactNode, useEffect, useRef, useState } from 'react';
import React from 'react';

library.add(faChevronRight, faChevronLeft);

interface ReviewCardProps {
  review: Review;
}

interface ReviewProps {
  reviewSite: string;
}

interface ReviewMoreProps {
  reviewSite: string;
}

interface StarsProps {
  isLarge: boolean;
  rating: number;
}

export function GoogleReviews(prop: ReviewProps) {
  const url: string | undefined = process.env.REACT_APP_URL;
  const { reviewSite } = prop;
  const { reviews, isLoading, reviewCount, rating } = useGoogleReviewData(url);
  const [reviewNodes, setReviewNodes] = useState<ReactNode[]>([]);
  const containerRef = React.createRef<HTMLDivElement>();
  const widgetRef = React.createRef<HTMLDivElement>();
  const cardContainerRef = React.createRef<HTMLDivElement>();
  const isMobile = window.outerWidth < 520;

  const resize = () => {
    if (
      !containerRef.current ||
      !widgetRef.current ||
      !cardContainerRef.current
    )
      return;

    containerRef.current.offsetWidth > cardContainerRef.current.offsetWidth
      ? 520 > widgetRef.current.offsetWidth
        ? containerRef.current.setAttribute('class', 'widget-container col-1')
        : 750 > widgetRef.current.offsetWidth
        ? containerRef.current.setAttribute('class', 'widget-container col-2')
        : 1100 > widgetRef.current.offsetWidth
        ? containerRef.current.setAttribute('class', 'widget-container col-3')
        : 1450 > widgetRef.current.offsetWidth
        ? containerRef.current.setAttribute('class', 'widget-container col-4')
        : 1800 > widgetRef.current.offsetWidth
        ? containerRef.current.setAttribute('class', 'widget-container col-5')
        : containerRef.current.setAttribute('class', 'widget-container col-6')
      : 540 > widgetRef.current.offsetWidth
      ? containerRef.current.setAttribute('class', 'widget-container col-1')
      : 760 > widgetRef.current.offsetWidth
      ? containerRef.current.setAttribute('class', 'widget-container col-2')
      : 1200 > widgetRef.current.offsetWidth
      ? containerRef.current.setAttribute('class', 'widget-container col-3')
      : 1550 > widgetRef.current.offsetWidth
      ? containerRef.current.setAttribute('class', 'widget-container col-4')
      : containerRef.current.setAttribute('class', 'widget-container col-5');
  };

  useEffect(() => {
    if (reviews.length === 0) return;
    const arr: ReactNode[] = [];
    for (let i = 0; i < reviews.length; i++) {
      if (i >= MAX_CARDS) {
        arr.push(<ReviewMore reviewSite={reviewSite} />);
        break;
      }
      arr.push(<ReviewCard review={reviews[i]} />);
    }

    setReviewNodes(arr);
    resize();
  }, [reviews]);

  return (
    <>
      {isLoading ? null : (
        <>
          <div className="slider">
            <div className="widget" ref={widgetRef}>
              <div
                className="widget-container"
                style={isMobile ? { flexDirection: 'column' } : undefined}
                ref={containerRef}
              >
                <div
                  className="widget-footer"
                  onClick={() => {
                    window.open(reviewSite, '_blank');
                  }}
                >
                  <div className="google-logo">
                    <div className="google-center">
                      <GoogleIcon className="logo" width={150} height={25} />
                    </div>
                  </div>
                  <StarRatings rating={rating} isLarge={true} />
                  <div>
                    <strong className="rating"> EXCELLENT </strong>
                    <span className="nowrap">
                      Based on{' '}
                      <strong>
                        <a className="status" href={reviewSite}>
                          {`${reviewCount} reviews`}
                        </a>
                      </strong>
                    </span>
                  </div>
                </div>
                <div
                  className="card-container"
                  id="cardContainer"
                  ref={cardContainerRef}
                >
                  <CardSlider cards={reviewNodes} isMobile={isMobile} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

function ReviewCard(props: ReviewCardProps): JSX.Element {
  const { review } = props;
  const contentRef = React.createRef<HTMLDivElement>();
  const spanRef = React.createRef<HTMLSpanElement>();
  const [initHeight, setInitHeight] = React.useState<number>(0);
  const [isExpand, setIsExpand] = React.useState<boolean>(false);
  const [readMoreShow, setReadMoreShow] = React.useState<boolean>(false);

  const expand = () => {
    if (!contentRef.current || !spanRef.current) return;
    if (isExpand) {
      contentRef.current.style.setProperty(
        'height',
        `${initHeight}px`,
        'important'
      );

      spanRef.current.classList.remove('collapse');
      contentRef.current.style.setProperty(
        '-webkit-line-clamp',
        '4',
        'important'
      );
    } else {
      if (initHeight === 0) {
        setInitHeight(contentRef.current.offsetHeight);
      }
      console.log('CHANGE');
      contentRef.current.style.removeProperty('-webkit-line-clamp');
      contentRef.current.style.setProperty(
        'height',
        `${contentRef.current.scrollHeight}px`,
        'important'
      );
      spanRef.current.classList.add('collapse');
    }
    setIsExpand(!isExpand);
  };

  useEffect(() => {
    if (!contentRef.current) return;
    setReadMoreShow(
      contentRef.current.scrollHeight <= contentRef.current.clientHeight
    );
  }, []);

  return (
    <div className="card-item" id="card-item">
      <div className="card-inner">
        <div className="card-header">
          <div className="profile-img">
            <img src={review.imgAvatar} alt={review.name} className="profile" />
          </div>
          <div className="profile-details">
            <div className="profile-name">{review.name}</div>
            <div className="profile-date">{review.time}</div>
          </div>
        </div>
        <StarRatings rating={review.rating} isLarge={false} />
        <div
          ref={contentRef}
          className="review-comment"
          style={{ WebkitLineClamp: 4 }}
        >
          {review.comment}
        </div>
        {readMoreShow ? null : (
          <span className="read-more" ref={spanRef} onClick={() => expand()}>
            <span>Read more</span>
          </span>
        )}
      </div>
    </div>
  );
}

function ReviewMore(prop: ReviewMoreProps): JSX.Element {
  const { reviewSite } = prop;
  return (
    <div
      className="card-item"
      id="card-item"
      onClick={() => {
        window.open(reviewSite, '_blank');
      }}
    >
      <div className="card-inner">
        <div className="viewMore">
          <strong>View More ➡</strong>
        </div>
      </div>
    </div>
  );
}

export function StarRatings(props: StarsProps) {
  const { rating, isLarge } = props;
  const [ratingNodes, setRatingNodes] = React.useState<ReactNode[]>([]);
  useEffect(() => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);
    const arr: ReactNode[] = [];
    for (let i = 0; i < fullStars; i++) {
      arr.push(<span className="star full" key={`full_${i}`}></span>);
    }

    // Add half star
    if (hasHalfStar) {
      arr.push(<span className="star half" key={'half'}></span>);
    }

    // Add empty stars
    for (let i = 0; i < emptyStars; i++) {
      arr.push(<span className="star empty" key={`empty_${i}`}></span>);
    }

    setRatingNodes(arr);
  }, []);

  return (
    <span className={`container-stars ${isLarge ? 'star-large' : ''}`}>
      {ratingNodes}
    </span>
  );
}

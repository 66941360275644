import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, RefObject, useEffect } from 'react';
import {
  createRef,
  LegacyRef,
  MouseEventHandler,
  ReactNode,
  useRef,
  useState,
} from 'react';

export interface DotInterface {
  isActive: boolean;
}

export interface CardSliderProps {
  cards: ReactNode[];
  isMobile: boolean;
}

export const MAX_CARDS = 9;

export function CardSlider(props: CardSliderProps): JSX.Element {
  const { cards, isMobile } = props;
  const [pagerState, setPagerState] = useState<number>(0);
  const [pagerPosition, setPagerPosition] = useState<string>('0px');
  const [isMoving, setMoving] = useState<boolean>(false);
  const [touchStartX, setTouchStartX] = useState<number>();
  const [touchMoveX, setTouchMoveX] = useState<number>();
  const [dots, setDots] = useState<ReactNode[]>([]);
  const next = React.createRef<SVGSVGElement>();
  const prev = React.createRef<SVGSVGElement>();

  useEffect(() => {
    const cardItem = document.getElementById('card-item');
    const cardContainer = document.getElementById('cardContainer');
    if (!cardItem || !cardContainer) return;
    const visibleCount = Math.floor(
      cardContainer.offsetWidth / cardItem.offsetWidth
    );
    const dotCount = 1 + Math.min(cards.length, MAX_CARDS) - visibleCount;

    const arr: ReactNode[] = [];

    for (let i = 0; i < dotCount; i++) {
      if (i === 0) {
        arr.push(<Dot isActive={true} />);
      } else {
        arr.push(<Dot isActive={false} />);
      }
    }
    setDots(arr);
  }, [cards.length]);

  const slide = (isRight: boolean) => {
    const duration = 500;
    const a = document.getElementById('cardContainer');
    const cardItem = document.getElementById('card-item');
    const slider = document.getElementById('slider');
    if (
      !a ||
      !cardItem ||
      !slider ||
      (!isMobile && (!prev.current || !next.current))
    )
      return;
    if (pagerState < 0) return;
    const tempPager = pagerState + (isRight ? 1 : -1);
    const visibleCount = Math.floor(a.offsetWidth / cardItem.offsetWidth);
    if (!isMobile) {
      if (tempPager > cards.length - visibleCount) {
        next.current!.style.display = 'none';
        return;
      }
      if (tempPager < 0) {
        prev.current!.style.display = 'none';
        return;
      }
    }

    const moveAmount =
      (-1 * tempPager * slider.offsetWidth) / visibleCount + 'px';
    console.log(tempPager, cards.length);
    a.querySelectorAll('.card-item').forEach(function (e) {
      e.animate(
        {
          left: [pagerPosition, moveAmount],
        },
        {
          duration: duration,
          fill: 'both',
          easing: 'ease-in-out',
        }
      );
      setTimeout(() => {
        if (!isVisible(e as HTMLDivElement)) {
          let f = e.querySelector('.collapse') as HTMLSpanElement;
          f && f.click();
        }
      }, duration);
    });

    setPagerPosition(moveAmount);
    setPagerState(tempPager);
    setTimeout(function () {
      setMoving(!1);
    }, duration);

    const newDots = dots.map((dot, i) => {
      if (i === tempPager) return <Dot isActive={true} />;
      else if (i === pagerState) return <Dot isActive={false} />;
      else return dot;
    });
    setDots(newDots);

    if (!isMobile) {
      next.current!.style.display =
        tempPager === cards.length - visibleCount ? 'none' : 'block';
      prev.current!.style.display = tempPager === 0 ? 'none' : 'block';
    }
  };

  const isVisible = (a: HTMLDivElement): boolean => {
    let b = document.getElementById('slider');
    if (!b) return false;
    const c = a.offsetLeft - b.offsetLeft;
    const finalA = c + a.offsetWidth;
    return c >= b.scrollLeft && finalA <= b.scrollLeft + b.offsetWidth;
  };

  const moveSliderMobile = (e: React.TouchEvent<HTMLDivElement>) => {
    // alert('Hello');
    const a = document.getElementById('cardContainer');
    if (
      !a ||
      !touchStartX ||
      !touchMoveX ||
      60 >= Math.abs(touchStartX - touchMoveX)
    )
      return;

    const d = touchStartX > touchMoveX;
    a.querySelectorAll('.review-comment').forEach(function (e) {
      e.scrollTop = 0;
    });
    slide(d);
  };

  return (
    <>
      {isMobile ? null : (
        <div className="controls">
          <FontAwesomeIcon
            ref={next}
            icon={'chevron-right'}
            className="next"
            color="white"
            onClick={() => slide(true)}
          />
          <FontAwesomeIcon
            ref={prev}
            id="prev"
            icon={'chevron-left'}
            className="prev"
            color="white"
            onClick={() => slide(false)}
            style={{ display: 'none' }}
          />
        </div>
      )}
      <div
        className="cards-wrapper"
        id="slider"
        onTouchStart={(e) => setTouchStartX(e.touches[0].pageX)}
        onTouchMove={(e) => setTouchMoveX(e.touches[0].pageX)}
        onTouchEnd={moveSliderMobile}
      >
        {cards}
      </div>
      <div className="dots-control">{dots}</div>
    </>
  );
}

function Dot(props: DotInterface) {
  const { isActive } = props;
  return <div className={`dot ${isActive ? 'active' : ''}`} />;
}
